import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import React from 'react';
import { Meta } from '../components/Meta';
import { RichText } from '../components/RichText';
import { TYPESET } from '../styles/mixins';
import PageTemplate from '../templates/page';

export default withUnpublishedPreview(NotfoundPage as any, {
  templateMap: {
    project: PageTemplate
  }
});

function NotfoundPage({ data: query }: { data: GatsbyTypes.NotfoundQuery }) {
  const { data } = query.prismicNotfound!;

  if (!data) {
    return null;
  }

  return (
    <>
      <Meta title={data.meta_title} description={data.meta_description} />
      <div
        css={css`
          ${TYPESET.scale0}
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          min-height: 100vh;
          padding: var(--padding) 0;
          font-weight: 500;
        `}
      >
        <RichText content={data.content!.html as string} />
      </div>
    </>
  );
}

export const query = graphql`
  query Notfound {
    prismicNotfound {
      _previewable
      data {
        meta_title
        meta_description
        content {
          html
        }
      }
    }
  }
`;
